body {
  background-color: rgb(38, 38, 38);
  margin: 0;
}
.sc-emBnGJ {
  background-color: rgb(38, 38, 38) !important;
  padding: 30px 0;
}
.sc-emBnGJ > img {
  width: 180px !important;
  margin: auto;
}
.sc-kIPQKe {
  background-color: rgb(38, 38, 38) !important;
}

.api-content,
.sc-kRayXq,
.sc-dNtQuv,
.sc-hUOJWJ {
  background-color: rgb(33, 33, 33) !important;
}
.sc-dNtQuv {
  border-color: rgb(59, 59, 59) !important;
  border-radius: 4px;
}
.react-tabs__tab--selected {
  color: #00d89e !important;
}

.hNHiKD > span {
  color: unset !important;
}
.sc-duSInm,
.sc-dzbdsH,
.sc-emqRaN {
  color: white !important;
}
.sc-dgDMAE {
  border-radius: 4px;
}
.sc-IhgdQ {
  display: none;
}

/* ======= styling ===== */
.screen-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.ant-notification-notice {
  background-color: #1f1f1f !important;
}
.table-full {
  display: inline-table !important;
}
.table-full tr {
  background-color: transparent !important;
}
.table-full th {
  font-weight: 400 !important;
}

pre > code {
  margin-bottom: 2em;
}

/* Prestilo del codigo y personalizado */
pre code {
  display: block;
  overflow-x: auto;
  padding: 1em;
  background: #000;
  color: #eaeaea;
  padding: 3px 5px;
  border-radius: 5px;
}

/* Estilo del codigo */
pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}
code.hljs {
  padding: 3px 5px;
}

.hljs {
  background: #000;
  color: #eaeaea;
}

.hljs-emphasis {
  font-style: italic;
}
.hljs-strong {
  font-weight: 700;
}

.hljs-comment,
.hljs-quote {
  color: #969896;
}
.hljs-deletion,
.hljs-name,
.hljs-regexp,
.hljs-selector-class,
.hljs-selector-id,
.hljs-tag,
.hljs-template-variable,
.hljs-variable {
  color: #d54e53;
}
.hljs-built_in,
.hljs-link,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-params,
.hljs-type {
  color: #e78c45;
}
.hljs-attribute {
  color: #e7c547;
}
.hljs-addition,
.hljs-bullet,
.hljs-string,
.hljs-symbol {
  color: #b9ca4a;
}
.hljs-section,
.hljs-title {
  color: #7aa6da;
}
.hljs-keyword,
.hljs-selector-tag {
  color: #c397d8;
}
